import React, { Component } from 'react';
import './notFound.css'; 

class NotFound extends Component {
  state = {}; 
  render() { 
    return (
      <div className='Not-Found'>
        <h1 >Page not found route</h1>
      </div>
    );
  }
}

export default NotFound;
