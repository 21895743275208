import React, { Component } from 'react';

class Downloads extends Component {
   state = {  } 
   render() { 
      return (
         <div className="Downlods">
            <h1>DOWNLOADS</h1>
         </div>
      );
   }
}
 
export default Downloads;